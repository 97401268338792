import React from "react";
import { TOptions } from "i18next";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import i18n from "../i18n/i18n";
import SEO from "../components/SEO";
import IndexHeroSection from "../components/pages/index/Hero";
import IndexBrandsSection from "../components/pages/index/Brands";

const Home = ({ pageContext }) => {
  const t = (key: string, options?: TOptions) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  const sectionWidth = "w-4/5 md:w-8/12 lg:w-7/12 2xl:w-5/12 m-auto";
  const pictures = useStaticQuery(graphql`
    query {
      services: allFile(
        filter: { relativeDirectory: { regex: "/services/" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                tracedSVGOptions: { blackOnWhite: true }
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
      certificates: allFile(
        filter: { relativeDirectory: { regex: "/certificates/" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                tracedSVGOptions: { blackOnWhite: true }
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
      brands: allFile(filter: { relativeDirectory: { regex: "/brands/" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                tracedSVGOptions: { blackOnWhite: true }
                placeholder: TRACED_SVG
                width: 550
                transformOptions: { grayscale: true }
              )
            }
          }
        }
      }
    }
  `);

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={t("indexPage.SEO.title")}
        description={t("indexPage.SEO.description")}
        pageContext={pageContext}
      ></SEO>
      <IndexHeroSection pageContext={pageContext} sectionWidth={sectionWidth} />
      <section className={`${sectionWidth} mt-20`}>
        <h2 className="uppercase mb-4 text-3xl">
          {t("indexPage.ABOUT.heading")}
        </h2>
        <p className="text-justify">{t("indexPage.ABOUT.description")}</p>
        <h3 className="text-gray-500 text-2xl mt-5 capitalize">
          {t("indexPage.ABOUT.awards")}
        </h3>
        <div className="mt-4 flex justify-start items-center gap-2">
          {pictures.certificates.edges
            .sort(
              (edge1: any, edge2: any) =>
                edge2.node.base.split(".")[0].split("_")[1] -
                edge1.node.base.split(".")[0].split("_")[1]
            )
            .map((edge: any, key: number) => {
              const [alt, ..._role] = edge.node.base.split(".")[0];
              return (
                <a
                  href="https://nbr.com.ua/about-us"
                  target="_blank"
                  rel="noopener"
                  key={key}
                >
                  <GatsbyImage
                    image={getImage(edge.node) as any}
                    alt={alt}
                    className="w-20 h-20 flex justify-center items-center"
                  />
                </a>
              );
            })}
        </div>
        <h3 className="text-gray-500 text-2xl mt-10 capitalize">
          {t("indexPage.ABOUT.achievements")}
        </h3>
        <div
          className={`grid grid-rows-auto grid-cols-1 gap-5 md:grid-cols-2 mt-5`}
        >
          {(
            t("indexPage.ABOUT.stats", {
              returnObjects: true,
              replace: { years: `${new Date().getFullYear() - 1993}` },
            }) as any
          ).map((stat, key) => {
            return (
              <div className="flex items-center justify-start gap-10" key={key}>
                <p className="text-5xl font-bold w-20 text-center text-blue-900">
                  {stat.value}
                </p>
                <p className="break-words text-gray-500">{stat.description}</p>
              </div>
            );
          })}
        </div>
      </section>
      <IndexBrandsSection
        brandImages={pictures.brands}
        pageContext={pageContext}
        sectionWidth={sectionWidth}
      />
      <section className={`${sectionWidth} mt-20`}>
        <h2 className="uppercase mb-4 text-3xl">
          {t("indexPage.SERVICES.heading")}
        </h2>
        <p className="text-justify">{t("indexPage.SERVICES.description")}</p>
        <div className="grid grid-cols-1 xl:grid-cols-3 grid-rows-1 gap-5 mt-5">
          {pictures.services.edges.map((edge: any, key: number) => {
            const name: any = edge.node.base.split(".")[0];
            return (
              <div className={`h-60 relative`} key={key}>
                <div className="absolute inset-0 z-0 h-full">
                  <GatsbyImage
                    className="absolute z-0 inset-0 h-full"
                    image={getImage(edge.node) as any}
                    alt={`${name}`}
                  />
                  <div className="absolute z-1 inset-0 h-full w-full bg-black bg-opacity-50"></div>
                </div>
                <div className="relative z-1 p-5 h-full flex flex-col justify-between">
                  <h3 className="text-white uppercase text-bold text-gray-200 text-3xl xl:text-xl break-all">
                    {
                      (
                        t("indexPage.SERVICES.services", {
                          returnObjects: true,
                        }) as any
                      )[name].name
                    }
                  </h3>
                  <Link
                    to={
                      (
                        t("indexPage.SERVICES.services", {
                          returnObjects: true,
                        }) as any
                      )[name].link
                    }
                    className="text-white hover:text-blue-600 underline text-right xl:text-left"
                  >
                    {
                      (
                        t("indexPage.SERVICES.services", {
                          returnObjects: true,
                        }) as any
                      )[name].action
                    }
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className={`${sectionWidth} mt-20`}>
        <h2 className="uppercase mb-4 text-3xl">
          {t("indexPage.PEOPLE.heading")}
        </h2>
        <p>{t("indexPage.PEOPLE.description")}</p>
        <Link to={t("indexPage.PEOPLE.link")} className="underline block mt-5 hover:text-blue-600">
          {t("indexPage.PEOPLE.action")}
        </Link>
      </section>
    </Layout>
  );
};

export default Home;
