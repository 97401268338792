import { Link } from "gatsby";
import { TOptions } from "i18next";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n/i18n";

// markup
const IndexHeroSection = ({ pageContext, sectionWidth }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => (counter === adj.length - 1 ? 0 : counter + 1));
    }, duration);
    return () => clearInterval(interval);
  }, []);

  const t = (key: string, options?: TOptions) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  const actionButton =
    "px-5 py-3 mt-5 text-lg text-white transition-colors duration-150 bg-blue-900 focus:shadow-outline hover:bg-white hover:text-black hover:border-black border border-blue-900 inline-block capitalize";
  const adj: string[] = t("indexPage.HERO.adj", { returnObjects: true }) as any;
  const duration = 3500;
  const [counter, setCounter] = useState(0);
  return (
    <section className="relative h-screen flex flex-col items-center justify-center bg-hero-image bg-no-repeat bg-cover bg-center">
      <div className="absolute w-full bottom-0 h-2/3 bg-gradient-to-b from-transparent to-white z-0"></div>
      <div className={`${sectionWidth} relative z-1 mb-20`}>
        <div className="">
          <h2 className="inline-block text-black text-xl md:text-4xl 2xl:text-6xl">
            {t("indexPage.HERO.adj", { returnObjects: true })[counter]}
          </h2>
        </div>
        <div>
          <h2 className="inline-block capitalize text-black font-bold text-3xl md:text-5xl 2xl:text-8xl">
            {t("indexPage.HERO.description")}
          </h2>
        </div>
        <Link to={t("indexPage.HERO.link")} className={actionButton}>
          {t("indexPage.HERO.action")}
        </Link>
      </div>
    </section>
  );
};

export default IndexHeroSection;
