import React from "react";
import { TOptions } from "i18next";
import { Autoplay, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import i18n from "../../../i18n/i18n";

const IndexBrandsSection = ({ pageContext, sectionWidth, brandImages }) => {
  const t = (key: string, options?: TOptions) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  const meta = {
    "atlas-copco": {
      url: "https://www.atlascopco.com/",
    },
    ceresit: {
      url: "https://ceresit.ua/",
    },
    dewalt: {
      url: "https://www.dewalt.com/",
    },
    gorstal: {
      url: "https://www.gor-stal.pl/",
    },
    hilti: {
      url: "https://www.hilti.com/",
    },
    karcher: {
      url: "https://www.kaercher.com/int/",
    },
    leister: {
      url: "https://www.leister.com/en",
    },
    "maris-polymers": {
      url: "https://www.marispolymers.com/",
    },
    milwaukee: {
      url: "https://www.milwaukeetool.com/",
    },
    naftogaz: {
      url: "https://www.naftogaz.com/www/3/nakweben.nsf",
    },
    primer: {
      url: "https://primer.com.ua/",
    },
    putzmeister: {
      url: "https://www.putzmeister.com/web/european-union",
    },
    "saint-gobain": {
      url: "https://www.saint-gobain.com/en",
    },
    sika: {
      url: "https://www.sika.com/en/home.html",
    },
    soprema: {
      url: "https://www.soprema.com/en/",
    },
    sweetondale: {
      url: "https://en.technonicol.eu/",
    },
  };

  const slides = brandImages.edges.map((edge, key) => {
    return (
      <SwiperSlide
        className="swiper-slide flex items-center justify-center w-24"
        key={key}
      >
        <a
          href={meta[edge.node.base.split(".")[0].toLowerCase()].url}
          target="_blank"
          rel="noopener"
        >
          <GatsbyImage image={getImage(edge.node) as any} alt={`test-images`} />
        </a>
      </SwiperSlide>
    );
  });

  return (
    <section className={`${sectionWidth} overflow-hidden`}>
      <h3 className={`text-gray-500 text-2xl mt-10 capitalize`}>
        {t("indexPage.BRANDS.heading")}
      </h3>
      <div className="relative mt-5">
        <div className="absolute w-1/6 h-full top-0 left-0 bg-gradient-to-r from-white z-10 pointer-events-none"></div>
        <div className="absolute w-1/6 h-full top-0 right-0 bg-gradient-to-l from-white z-10 pointer-events-none"></div>
        <Swiper
          modules={[Autoplay, Mousewheel]}
          {...{
            slidesPerView: "auto",
            onSwiper: (swiper) => swiper.$wrapperEl.addClass("items-center"),
            spaceBetween: 50,
            initialSlide: Math.floor(brandImages.edges.length / 2),
            centeredSlides: true,
            autoplay: {
              delay: 3500,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            },
            mousewheel: true,
          }}
        >
          {slides}
        </Swiper>
      </div>
    </section>
  );
};

export default IndexBrandsSection;
